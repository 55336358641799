<template>
  <div class="card-planTiers-compta">
    <v-btn
      small
      class="btn-add-setting mr-1"
      @click.prevent="handelModalplanTiers('show')"
    >
      <font-awesome-icon icon="copy" class="mr-3" /> Copier l'information de
      plan tiers
    </v-btn>
    <v-dialog
      v-model="planTiersModal"
      persistent
      max-width="990px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add custom-vuetify-dialog-planTiers-conta"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Mettre àjour les données de plan tiers
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handelModalplanTiers('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
          <!-- COMPNAY TO USE  -->
          <v-row class="mt-4">
            <!-- SOCIETE -->
            <v-autocomplete
              @change="setComptaData"
              :clearable="true"
              v-model="filiale_id"
              :items="getFiliaeOfResponsableCopy"
              item-text="name"
              item-value="id"
              color="#704ad1"
              item-color="#704ad1"
              class="mb-1"
              placeholder="Séléctionnez"
              :loading="isLoadingS"
              :persistent-placeholder="true"
              label="Société"
              return-object
              required
              dense
              :auto-focus="false"
              no-data-text="Aucune société trouvée"
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
            >
            </v-autocomplete>
          </v-row>
          <div v-if="isLoadingT">
            <v-progress-circular
              class="mt-4"
              style="margin-left:50%"
              v-if="isLoadingT"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
          </div>
          <!-- CLIENT DATA  -->
          <div
            class="card-style pt-2"
            v-for="(plan, index) in planTiers"
            :key="'compta-tiers' + index"
          >
            <span class="title-card"
              >Compte Tiers {{ computedClietnName(plan.client_id) }}</span
            >
            <v-row class="mt-0 justify-end" v-if="!plan.id">
              <v-btn
                icon
                @click.prevent.stop="deleteClient(index)"
                title="Supprimer"
                color="#704ad1"
              >
                <font-awesome-icon icon="trash" class="fa-lg" />
              </v-btn>
            </v-row>
            <!-- COMPNAY TO USE  -->
            <v-row class="mt-1">
              <!-- SOCIETE -->
              <v-col cols="4">
                <v-autocomplete
                  :clearable="true"
                  v-model="plan.client_id"
                  :items="getFiliaeOfResponsableCopy"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1 msg-error"
                  placeholder="Séléctionnez"
                  :loading="getFactureLibreLoadingTh"
                  :persistent-placeholder="true"
                  label="Client"
                  required
                  :rules="[v => !!v || 'Client obligatoire']"
                  validate-on-blur
                  dense
                  :disabled="plan.id ? true : false"
                  :auto-focus="false"
                  no-data-text="Aucun Client trouvé"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete
              ></v-col>
              <v-col cols="4">
                <v-text-field
                  label="Compte client"
                  mess
                  :persistent-placeholder="true"
                  v-model="plan.client"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Compte fournisseur"
                  mess
                  :persistent-placeholder="true"
                  v-model="plan.fournisseur"
                  outlined
                  color="#704ad1"
                >
                </v-text-field> </v-col
            ></v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Compte reste à charges"
                  mess
                  :persistent-placeholder="true"
                  v-model="plan.reste_charge"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Compte commission"
                  mess
                  :persistent-placeholder="true"
                  v-model="plan.commission"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Compte prime"
                  mess
                  :persistent-placeholder="true"
                  v-model="plan.prime"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handelValidData"
            :loading="isLoading"
            :disabled="isLoading"
            :class="{ loader: isLoading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handelModalplanTiers('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: { dataToUse: { required: true } },
  data() {
    return {
      planTiersModal: false,
      planTiers: [],
      error: null,
      isLoading: false,
      isLoadingS: false,
      filiale_id: null,
      isLoadingT: false
    };
  },
  computed: {
    ...mapGetters(['getFiliaeOfResponsableCopy', 'getFactureLibreLoadingTh']),
    computedClietnName() {
      return function(data) {
        if (data) {
          let item = this.getFiliaeOfResponsableCopy.filter(i => i.id == data);
          if (item.length > 0) {
            item = item[0].name;
          } else {
            item = '';
          }
          return item;
        }
      };
    }
  },
  methods: {
    ...mapActions([
      'getFilialsOfConnectedResponsableCoy',
      'getOneFilialeDataCompta',
      'getPlanTiersOfFiliale'
    ]),
    deleteClient(indexE) {
      this.planTiers.splice(indexE, 1);
    },
    async handelModalplanTiers(action) {
      if (action == 'show') {
        this.planTiers = JSON.parse(JSON.stringify(this.dataToUse));
        this.planTiersModal = true;
        this.isLoadingS = true;
        await this.getFilialsOfConnectedResponsableCoy();
        this.isLoadingS = false;
      } else {
        this.planTiersModal = false;
        this.resetModal();
      }
    },

    async setComptaData() {
      if (this.filiale_id != null) {
        //call api to get plan tiers data
        this.isLoadingT = true;
        const response = await this.getPlanTiersOfFiliale(this.filiale_id.id);
        if (response.succes) {
          let tiers_exist = this.planTiers;
          tiers_exist = tiers_exist.map(i => i.client_id);
          response.data.forEach(element => {
            if (tiers_exist.includes(element.client_id)) {
              let tierIndex = this.planTiers.findIndex(
                i => i.client_id == element.client_id
              );
              if (tierIndex >= 0) {
                this.planTiers[tierIndex].client =
                  this.planTiers[tierIndex].client == null ||
                  this.planTiers[tierIndex].client == 'null'
                    ? element.client
                    : this.planTiers[tierIndex].client;
                this.planTiers[tierIndex].fournisseur =
                  this.planTiers[tierIndex].fournisseur == null ||
                  this.planTiers[tierIndex].fournisseur == 'null'
                    ? element.fournisseur
                    : this.planTiers[tierIndex].fournisseur;

                this.planTiers[tierIndex].reste_charge =
                  this.planTiers[tierIndex].reste_charge == null ||
                  this.planTiers[tierIndex].reste_charge == 'null'
                    ? element.reste_charge
                    : this.planTiers[tierIndex].reste_charge;

                this.planTiers[tierIndex].commission =
                  this.planTiers[tierIndex].commission == null ||
                  this.planTiers[tierIndex].commission == 'null'
                    ? element.commission
                    : this.planTiers[tierIndex].commission;

                this.planTiers[tierIndex].prime =
                  this.planTiers[tierIndex].prime == null ||
                  this.planTiers[tierIndex].prime == 'null'
                    ? element.prime
                    : this.planTiers[tierIndex].prime;
              }
            } else {
              this.planTiers.push({
                client_id: element.client_id,
                client: element.client,
                fournisseur: element.fournisseur,
                reste_charge: element.reste_charge,
                commission: element.commission,
                prime: element.prime,
                is_active: 1
              });
            }
          });
        }
        this.isLoadingT = false;
      }
    },
    resetModal() {
      this.filiale_id = null;
      this.error = null;
      this.planTiers = [];
    },
    handelValidData() {
      this.$emit('validDataCompta', this.planTiers);
      this.handelModalplanTiers('hide');
    }
  }
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.v-dialog.custom-vuetify-dialog-planTiers-conta.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  top: 30px !important;
  position: absolute !important;
}
.custom-vuetify-dialog-planTiers-conta {
  .card-style {
    border-radius: 10px;
    border: 1px solid #b5b5b5;
    padding: 28px;
    margin-top: 16px;
    margin-bottom: 20px;
    position: relative;

    .title-card {
      position: absolute;
      top: -12px;
      font-size: 12px;
      background-color: #fff;
      padding-left: 5px;
      color: rgb(0 0 0 / 41%);
    }
  }
}
</style>
